.feather {
    width: $svg-icon-size;
    height: $svg-icon-size;
    fill: none;
    stroke: currentcolor;
    stroke-width: $svg-icon-stroke-width;
    stroke-linecap: $svg-icon-stroke-linecap;
    stroke-linejoin: $svg-icon-stroke-linecap;
}

.code-blocks{margin-top:25px}.section-webfont .section-footer{text-align:center;margin-top:90px}.webfont-step{margin:0 0 30px}.webfont-step h4{line-height:36px;font-size:18px;font-weight:600;color:#fff;margin:0 0 24px}.webfont-step h4 strong,.webfont-step h4 span{display:inline-block;vertical-align:top;font-weight:inherit}.webfont-step h4 strong{width:36px;height:36px;background-color:rgba(255,255,255,0.1);border-radius:50%;font-size:16px;text-align:center;margin:0 14px 0 0}.webfont-step p{font-size:16px;line-height:1.63;color:#9ebef4;margin:0}.webfont-step p b{font-weight:500;color:#ffffff}.code-blocks .code-block{padding:30px 32px;border-radius:20px;box-shadow:0 100px 75px 0 rgba(35,44,79,0.08);background-color:#ffffff;margin:0 0 16px}.code-blocks .code-block code{display:block;font-size:16px;font-weight:500;line-height:1.25;color:#134c81}.code-blocks .code-block code+code{margin-top:24px}
