//
// Docs layout
//

.docs {

    .header .navbar {
        position: fixed;
        z-index: 1000;
    }

    // Container

    .container-fluid {
        @include media-breakpoint-up(sm) {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }

        @include media-breakpoint-down(sm) {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
        }
    }

    .container-docs {
        display: flex;
        flex: 1;
        align-items: stretch;
    }

    // Main content

    .main-content {
        display: flex;
        flex: 1 1 auto;
        padding: 0;
        margin: 0;
        min-width: 0;
        max-width: 100%;
        height: 100vh;

        @include media-breakpoint-up(lg) {
            padding-left: $navbar-vertical-open-width;
        }


        .container,
        .container-fluid {
            position: relative;
        }
    }

    .main-content-title {
        +.row,
        +.card {
            margin-top: $grid-gutter-width;
        }
    }

    .docs-content {
        margin-top: 80px;

        @include media-breakpoint-up(lg) {
            padding: 0 70px;
        }

        @include media-breakpoint-up(md) {
            padding: 0 50px;
        }

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
    }

    .docs-title {
        padding: 2.5rem;
        margin: 2.5rem 0px;
        border: 2px solid $border-color;
        @include border-radius($border-radius-lg)
    }

    // Sidenav

    .sidenav {
        position: fixed;
        top: 0;
        height: 100vh;
        overflow: auto;
        margin-bottom: 0;
        border: 0;
        z-index: 900;
        @include border-radius($navbar-vertical-border-radius);
        @include transition($transition-base);

        @include media-breakpoint-up(lg) {
            max-width: $navbar-vertical-open-width !important;
            height: 100vh;
            transform: translateX(0);
            border-right: 1px solid $border-color;
            @include border-radius($navbar-vertical-open-border-radius);
        }

        @include media-breakpoint-down(md) {
            max-width: $navbar-vertical-width;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-150%);
            border: 0;
        }

        &.show {
            max-width: $navbar-vertical-open-width !important;
            transform: translateX(0);
            border-right: 1px solid $border-color;
            z-index: 900;
            @include border-radius($navbar-vertical-open-border-radius);
        }
    }

    // Sidenav header

    .sidenav-header {
        padding: 1.5rem 0;
        margin-bottom: 1.5rem;

        .navbar-brand {
            img {
                height: 1.5rem;
            }
        }
    }

    // Main content title

    .main-content-title {
        + .nav-tabs {
            margin-bottom: $grid-gutter-width;
        }

    }

    &.g-sidenav-pinned {
        .navbar-main {
            // padding-left: $navbar-vertical-open-width;
        }
    }
}
